import { saveCart } from "@/api/base";

const cart = {
  namespaced: true,
  state: () => ({
    goods: [],
  }),
  mutations: {
    SET_GOODS: (state, goods) => {
      state.goods = goods;
    },
    SET_GOOD_BY_ID: (state, data) => {
      let target = null;
      if (data.type === "clear") {
        state.goods.forEach((value) => {
          value.total = 0;
          value.userStock = [];
        });
        state.goods = [];
        return;
      }
      if (state.goods.indexOf(data.good) > -1) {
        target = data.good;
      }
      if (!target) {
        // 购物车中没有该对象必是新增
        data.good.userStock.push({
          id: new Date().getTime(),
          user: data.user,
          num: data.value,
          loading: false,
        });
        state.goods.push(data.good);
      } else {
        //减操作必有target
        for (let index in target.userStock) {
          if (target.userStock[index].user.openId === data.user.openId) {
            if (
              data.type === "minus" &&
              target.userStock[index].num === data.value
            ) {
              target.userStock.splice(index, 1);
              if (target.userStock.length <= 0) {
                state.goods.splice(state.goods.indexOf(target), 1);
              }
            } else {
              data.type === "plus"
                ? (target.userStock[index].num += data.value)
                : (target.userStock[index].num -= data.value);
            }
            return;
          }
        }
        target.userStock.push({
          id: new Date().getTime(),
          user: data.user,
          num: data.value,
          loading: false,
        });
      }
    },
  },
  actions: {
    checkout: ({ commit, rootState, state }, data) => {
      return new Promise((resolve, reject) => {
        if (
          data.good &&
          (data.value || 1) > data.good.total &&
          data.type === "minus"
        ) {
          state.goods.splice(state.goods.indexOf(data.good), 1);
          resolve(true);
          return;
        }
        data.good && (data.good.loading = true);
        data.type === "minus" && (data.user = data.good.userStock[0]?.user);
        const ws_str = JSON.stringify({
          user: data.user ? data.user : rootState.base.currentUser,
          type: data.type,
          value: data.value ? data.value : 1,
          good: { id: data.good?.id, pos: data.good?.pos },
        });
        commit("SET_GOOD_BY_ID", {
          user: data.user ? data.user : rootState.base.currentUser,
          good: data.good,
          value: data.value ? data.value : 1,
          type: data.type,
        });
        if (data.type === "plus") data.good.total += data.value || 1;
        else if (data.type === "minus") data.good.total -= data.value || 1;
        const target = state.goods.map((value) => ({
          id: value.id,
          pos: value.pos,
          total: value.total,
          userStock: value.userStock,
        }));
        saveCart(rootState.base.orderId, JSON.stringify(target))
          .then(() => {
            // 本地修改购物篮后 提交购物篮
            if (rootState.app.ws) {
              data.broadcast !== false && rootState.app.ws.send(ws_str);
            }
            data.good && (data.good.loading = false);
            resolve(true);
          })
          .catch(reject);
        data.broadcast === false && (data.good.loading = false);
      });
    },
  },
  getters: {
    getTotalCountAndPrice: (state) => {
      const result = { count: 0, price: 0 };
      state.goods.forEach((val) => {
        result.count += val.total || 0;
        result.price +=
          (val.member_price ? val.member_price : val.price) * (val.total || 0);
      });
      result.price = result.price / 100;
      return result;
    },
  },
};
export default cart;
/**
 *goods数据结构: {userStock: [{name: user_1, num: 1, ...},...]}
 *userStock属性表示该菜品由n位用户选取，用于点菜详情页面展示每个用户的点菜详情
 *在订单展示页面无需渲染该属性
 **/
