import request from "@/utils/request";
export function menuList(query) {
  return request({
    baseURL: "/SntWxInterface",
    url: "/selectDinnersNum",
    method: "post",
    data: query,
  });
}
export function getMerchant(data) {
  return request({
    baseURL: "/SntWxInterface",
    url: "/scanCode",
    method: "post",
    data: data,
  });
}
export function saveCart(orderId, data) {
  return request({
    baseURL: "/SntWxInterface",
    url: "/saveShoppingCart",
    method: "post",
    data: { orderId, data },
  });
}
export function getShoppingCart(orderId) {
  return request({
    baseURL: "/SntWxInterface",
    url: "/getShoppingCart",
    method: "post",
    data: { orderId },
  });
}
export function takeOrder(data) {
  return request({
    baseURL: "/SntWxInterface",
    url: "/placeOrder",
    method: "post",
    data,
  });
}
export function createOrder(data) {
  return request({
    baseURL: "/SntWxInterface",
    url: "/createOrder",
    method: "post",
    data: data,
  });
}
export function findOrderById(orderId) {
  return request({
    baseURL: "/SntWxInterface",
    url: "/findOrderById",
    method: "post",
    data: { orderId },
  });
}
export function getTopGoods(merId, rows = 5) {
  return request({
    baseURL: "/SntWxInterface",
    url: "/topGoods",
    method: "post",
    data: { merId, rows },
  });
}
