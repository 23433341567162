<template>
  <div ref="wrapper" style="overflow: hidden; height: 100%">
    <slot />
  </div>
</template>

<script>
import BScroll from "@better-scroll/core";
import ObserveDOM from "@better-scroll/observe-dom";
export default {
  name: "scroll",
  data() {
    return {
      scroll: null,
      listener: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const wrapper = this.$refs.wrapper;
      wrapper.style.height = wrapper.clientHeight + "px";
      BScroll.use(ObserveDOM);
      this.scroll = new BScroll(wrapper, {
        probeType: 3,
        tap: true,
        click: true,
        mouseWheel: true,
        observeDOM: true, // 开启 observe-dom 插件
      });
      this.scroll.on("scroll", (position) => {
        this.$emit("scroll", position);
      });
      this.scroll.on("touchEnd", () => {
        this.$emit("scrollEnd");
      });
      this.scroll.on("scrollEnd", () => {
        this.$emit("scrollEnd");
      });
      this.scroll.on("beforeScrollStart", () => {
        this.$emit("scrollStart");
      });
    });
    this.listener = () => {
      if (this.scroll) this.refresh();
    };
    window.addEventListener("resize", this.listener);
  },
  methods: {
    scrollTo(x, y) {
      this.scroll.scrollTo(x, y, 700);
    },
    refresh() {
      this.$nextTick(() => {
        this.scroll.refresh();
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.listener);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        vm.refresh();
      });
    });
  },
};
</script>

<style scoped></style>
