import router from "./router";
import store from "./store";
import Cookies from "js-cookie";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
//let status = "forward";
window.addEventListener("popstate", () => {
  store.commit("app/SET_NAVI_STATUS", "backward");
});
router.beforeEach((to, from, next) => {
  NProgress.start();
  let direction = null;
  const token = Cookies.get("token");
  if (store.state.app.isError) {
    direction = to.name !== "Error" ? "/error" : null;
  } else {
    if (store.state.app.isMerchant) {
      if (!token) {
        direction = to.name === "Login" ? null : "Login";
      }
      // 商家端路由导航
      else if (to.name && !to.name.startsWith("Merchant")) {
        direction = "/merchantHome";
      }
    } else {
      console.log("user has permission?", store.getters["base/checkDataReady"]);
      if (to.name && to.name.startsWith("Merchant")) {
        direction = "/home";
      } else if (to.path !== "/home" && !store.getters["base/checkDataReady"]) {
        direction = `/home?redirect=${to.fullPath}`;
      } else if (to.path !== "/info" && store.state.base.eaterNum <= 0) {
        if (to.path !== "/home") {
          direction = "/info";
        }
      }
    }
  }
  if (direction) {
    next(direction);
  } else {
    next();
  }
});
router.afterEach((to) => {
  NProgress.done();
  if (to.meta.alive === false) {
    store.commit("app/ADD_NO_KEEP_ALIVE_LIST", to.name);
  } else {
    store.commit("app/ADD_KEEP_ALIVE_LIST", to.name);
  }
});
