let win = window;
let docEl = win.document.documentElement;
let tid;

function refreshRem() {
  let width = docEl.getBoundingClientRect().width;
  let height = docEl.getBoundingClientRect().height;
  let rem;
  if (width / height > 750 / 1334) {
    rem = height / (1334 / 50);
  } else {
    rem = width / (750 / 50);
  }
  docEl.style.fontSize = rem === 0 ? 12 : rem + "px";
}
const isAndroid = navigator.userAgent.indexOf("Android") > -1;
export const initViewport = function (height) {
  if (isAndroid) {
    return;
  }
  var metaEl = document.querySelector("#viewportMeta");
  var content =
    "width=device-width,initial-scale=1.0,user-scalable=no,maximum-scale=1.0,height=" +
    height;
  metaEl.setAttribute("name", "viewport");
  metaEl.setAttribute("content", content);
};

setInterval(() => {
  initViewport(window.innerHeight);
}, 500);
/*win.addEventListener(
  "resize",
  function () {
    clearTimeout(tid);
    tid = setTimeout(() => {
      refreshRem();
      initViewport(window.innerHeight);
    }, 300);
  },
  false
);*/
win.addEventListener(
  "pageshow",
  function (e) {
    if (e.persisted) {
      clearTimeout(tid);
      tid = setTimeout(refreshRem, 300);
    }
  },
  false
);
tid = setTimeout(refreshRem, 300);
