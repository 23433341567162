<template>
  <div
    class="full flex-column no-expand"
    style="padding: 0.6rem; align-items: center; box-sizing: border-box"
  >
    <div class="flex-column" style="align-items: center">
      <div
        style="width: fit-content; border-radius: 100%; background-color: white"
      >
        <van-icon color="#14c500" size="4rem" name="checked" />
      </div>
      <div style="padding: 0.6rem 0">
        <span style="font-size: 1rem; font-weight: bold">支付完成</span>
      </div>
      <div
        style="
          font-size: 0.8rem;
          color: #7e8c8d;
          font-weight: bold;
          padding-bottom: 0.4rem;
        "
      >
        感谢您的支持!
      </div>
      <van-button
        round
        plain
        type="primary"
        size="small"
        style="width: 4rem"
        to="/home"
        >确定</van-button
      >
    </div>
  </div>
</template>

<script>
import { Icon as VanIcon, Button as VanButton } from "vant";
export default {
  name: "OrderComplete",
  components: {
    VanIcon,
    VanButton,
  },
  // eslint-disable-next-line no-unused-vars
  beforeRouteEnter(to, from, next) {
    if (to.meta && to.meta.pay) {
      next();
    } else {
      next(from.path);
    }
  },
};
</script>

<style scoped></style>
