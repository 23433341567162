export const search = (id, list = []) => {
  let target = null;
  let pos = [0, 0];
  for (let item of list) {
    if (item.children && item.children.length > 0) {
      for (let child of item.children) {
        if (child.id === id) {
          pos = [list.indexOf(item), item.children.indexOf(child)];
          target = child;
          break;
        }
      }
    }
  }
  return { target, pos };
};
