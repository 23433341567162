<template>
  <div class="switch-container">
    <div
      class="switch-cover"
      :style="{ width: `${width}px`, transform: position }"
    ></div>
    <div
      ref="left"
      class="left-label switch-label"
      @click="switchLabel('left')"
    >
      {{ label.left.name }}
    </div>
    <div
      ref="right"
      class="right-label switch-label"
      @click="switchLabel('right')"
    >
      {{ label.right.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "mySwitch",
  props: {
    value: {
      type: String,
    },
    label: {
      type: Object,
    },
  },
  data() {
    return {
      pos: "left",
      width: 0,
      current: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const { left, right } = this.$refs;
      this.width = Math.max(left.clientWidth, right.clientWidth);
      // this.height = Math.max(left.clientHeight, right.clientHeight);
      this.current = this.label.left;
      this.pos = "left";
    });
  },
  computed: {
    position() {
      if (this.pos === "left") return "translateX(0)";
      else return "translateX(100%)";
    },
  },
  methods: {
    switchLabel(pos) {
      this.current = this.label[pos];
      this.pos = pos;
      this.$emit("input", this.label[pos].value);
      this.$emit("change");
    },
  },
};
</script>

<style lang="less" scoped>
.switch-container {
  display: flex;
  position: relative;
  font-size: 0.6rem;
  padding: 5px;
  background-color: #dddddd;
  border-radius: 10px;
  .switch-cover {
    transition: all 0.3s ease-in-out;
    position: absolute;
    left: 5px;
    top: 5px;
    bottom: 5px;
    background-color: #fff;
    border-radius: 8px;
  }
  .switch-label {
    padding: 5px;
    z-index: 3;
  }
}
</style>
