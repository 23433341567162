import Vue from "vue";
import Vuex from "vuex";

import base from "./modules/base";
import cart from "./modules/cart";
import app from "./modules/app";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    base,
    cart: cart,
    app,
  },
});
