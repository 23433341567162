<template>
  <div id="app" style="height: 100vh">
    <loading-layout :loading="loading" />
    <barrage ref="bar" />
    <div class="ab-container">
      <div class="animate-basement">
        <transition
          :name="status"
          @after-leave="$store.commit('app/SET_NAVI_STATUS', 'forward')"
        >
          <keep-alive :include="keepAlive">
            <router-view ref="route" />
          </keep-alive>
        </transition>
      </div>
    </div>
    <!--    <draggable
      v-show="
        ($route.name === 'Shopping' || $route.name === 'Cart') &&
        !$store.state.app.isMerchant
      "
      :position="{ left: '40px', bottom: '200px' }"
    >
      <div class="bar-icon" @click="$router.push('Home')">
        <div class="inner">
          <van-icon
            style="font-family: vant-icon"
            size="35px"
            name="records"
            color="white"
          />
        </div>
      </div>
    </draggable>-->
  </div>
</template>
<script>
import { mapState } from "vuex";
import { initViewport } from "@/utils/rem";
import LoadingLayout from "@/components/loadingLayout";
import Barrage from "@/components/barrage";
import { search } from "@/utils/common";
export default {
  components: { Barrage, LoadingLayout },
  data() {
    return {
      ws: null,
      loading: false,
      counter: 0,
      lastTime: 0,
    };
  },
  created() {
    const that = this;
    window.onpagehide = function (b) {
      localStorage.setItem("sid", that.sid);
      b = b || window.event;
      return b;
    };
  },
  mounted() {
    initViewport(window.innerHeight);
  },
  computed: {
    ...mapState({
      status: (state) => state.app.naviStatus,
      merchant: (state) => state.base.currentRestaurant,
      tableId: (state) => state.base.tableId,
      merchantId: (state) => state.base.merchantId,
      sid: (state) => state.base.sid,
      orderId: (state) => state.base.orderId,
      code: (state) => state.base.code,
      menu: (state) => state.base.totalGoods,
      keepAlive: (state) => state.app.keepAlive,
      num: (state) => state.base.eaterNum,
      user: (state) => state.base.currentUser,
    }),
  },
  watch: {
    merchant(val) {
      if (
        val.name &&
        !this.ws &&
        !this.$store.state.app.isMerchant &&
        !val.isSingle
      ) {
        this.connect();
      }
      if (val.isSingle) {
        this.loading = true;
        this.$store
          .dispatch("base/getMenu", 1)
          .then(() => {
            this.loading = false;
            this.$route.query.redirect
              ? this.$router.push(this.$route.query.redirect)
              : null;
          })
          .catch(() => {
            this.loading = false;
            this.$toast.fail("获取菜单失败，请重新扫码");
          });
      }
    },
  },
  methods: {
    connect(reConnect = false) {
      this.loading = true;
      try {
        this.ws = new WebSocket(
          process.env.VUE_APP_WS_RUL +
            `/myWS?opid=${this.user.openId}&sid=${this.sid}`
        );
        this.ws.onopen = () => {
          this.$store.commit("app/SET_WEBSOCKET", this.ws);
          if (this.orderId && !reConnect) {
            this.$store
              .dispatch("base/getMenu", this.num || 1)
              .then(() => {
                this.loading = false;
                if (this.$route.query.redirect) {
                  this.$router.push(this.$route.query.redirect);
                }
              })
              .catch(() => {
                this.loading = false;
                this.$toast.fail("获取菜单失败，请重新扫码");
              });
          } else if (this.orderId && reConnect) {
            this.$store.dispatch("base/getNewCart", {
              list: this.menu,
              orderId: this.orderId,
            });
          } else {
            this.$router.push("/info");
          }
        };
        this.ws.onerror = this.onError;
        this.ws.onclose = this.onclose;
        this.ws.onmessage = this.onMessage;
      } catch (e) {
        console.log(e);
      }
    },
    onMessage(e) {
      let { data } = e;
      data = JSON.parse(data);
      if (data.type === "plus" || data.type === "minus") {
        data.operation = data.type;
        if (data.good.pos) {
          const [x, y] = data.good.pos;
          const id = data.good.id;
          data.good = this.menu[x].children[y];
          if (data.good.id !== id) {
            data.good = search(data.good.id, this.menu).target;
          }
        } else {
          data.good = search(data.good.id, this.menu).target;
        }
        data.good.total === undefined ? this.$set(data.good, "total", 0) : null;
        data.good.loading === undefined
          ? this.$set(data.good, "loading", false)
          : (data.good.loading = false);
        data.good.userStock === undefined
          ? this.$set(data.good, "userStock", [])
          : null;
        data.broadcast = false;
        this.$store.dispatch("cart/checkout", data);
        if (data.type === "plus") {
          this.$refs.bar.addElement(data);
        }
      } else if (data.type === "generate") {
        this.$store
          .dispatch("base/getMenu", data.num)
          .then((res) => {
            if (res) {
              this.$router.push("/shopping");
            }
          })
          .catch(() => {
            this.$toast.fail("获取菜单失败，请重新扫码");
          });
      } else if (data.type === "clear") {
        this.$store.state.cart.goods.forEach((value) => {
          value.total = 0;
          value.userStock = [];
        });
        this.$store.commit("cart/SET_GOODS", []);
      } else if (data.type === "order") {
        this.$dialog
          .alert({
            message: "有人已经下单了！",
          })
          .then(() => {
            if (this.$refs.route.$options.name === "Home") {
              this.$refs.route.refresh();
            }
            this.$store.commit("cart/SET_GOODS", []);
            this.$router.push("/home");
          });
      } else if (data.type === "close") {
        this.$dialog
          .alert({
            title: "提示",
            message: "当前桌已关闭，请退出重新扫码！",
            theme: "round-button",
          })
          .then(() => {
            this.$store.commit(
              "app/SET_ERROR_MESSAGE",
              "商家已关闭该桌，请重新扫码"
            );
            this.$store.commit("app/SET_ERROR", true);
            this.$router.push("Error");
          });
      }
    },
    onError(e) {
      console.error(e);
      //this.connect(true);
      /*this.$dialog
        .alert({
          //message: "与远程服务器断开链接, 请稍后刷新页面",
          message: "欢迎回来, 祝您用餐愉快",
        })
        .then(() => {
          // on close
        });*/
    },
    onclose(e) {
      console.error(e);
      const now = new Date().getTime();
      const time = now - this.lastTime;
      if (time <= 5000) {
        this.counter = this.counter + 1;
      } else {
        this.counter = 0;
      }
      this.lastTime = now;
      if (this.counter >= 2) {
        if (this.ws && this.ws.readyState === this.ws.CLOSED)
          console.log("ws close!");
      } else {
        this.connect(true);
      }
    },
  },
};
</script>
<style lang="less" scoped>
#app {
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  /*background: url("./assets/image/back2.png") no-repeat fixed;*/
  background: url("http://qiniu.sinemtu.com/sdb-base_home-bg.png") no-repeat
    fixed;
  background-size: cover;
  position: relative;
  .ab-container {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    .animate-basement {
      height: 100%;
      width: 100%;
      position: relative;
    }
  }
  &:after {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    filter: blur(2px);
    transform: rotate(180deg);
    z-index: 1;
    background: inherit;
  }
  * {
    font-family: "PingFang SC", sans-serif;
  }
}
</style>
<style lang="css">
/* http://meyerweb.com/eric/tools/css/reset/
     v2.0 | 20110126
     License: none (public domain)
  */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
</style>
<style lang="less" scoped>
.bar-icon {
  background-color: @primary-color;
  box-shadow: 2px 2px 7px 0 #804040;
  border-radius: 50%;
  padding: 3px 3px;
  .inner {
    padding: 5px 5px;
    border-radius: 50%;
    border: 3px solid #fff;
    height: 35px;
  }
  height: 51px;
}
</style>
