import { getMerchant, getShoppingCart, menuList } from "@/api/base";
import { search } from "@/utils/common";
import { Toast } from "vant";
import Cookies from "js-cookie";
import Vue from "vue";
export default {
  namespaced: true,
  state: () => ({
    currentUser: {},
    currentRestaurant: {},
    eaterNum: 0,
    totalGoods: [],
    merchantId: null,
    tableId: null,
    orderId: null,
    sid: null,
    code: null,
    orderDone: [],
    orderUnDone: [],
  }),
  mutations: {
    SET_ORDER_UNDONE: (state, list) => {
      state.orderUnDone = list;
    },
    SET_ORDER_DONE: (state, list) => {
      state.orderDone = list;
    },
    SET_SID: (state, sid) => {
      state.sid = sid;
    },
    SET_CURRENT_EATER_NUM: (state, num) => {
      state.eaterNum = num;
    },
    SET_CURRENT_RESTAURANT: (state, data) => {
      state.currentRestaurant = data;
    },
    SET_CURRENT_USER: (state, user) => {
      state.currentUser = user;
    },
    SET_TOTAL_GOODS: (state, total) => {
      state.totalGoods = total;
    },
    SET_MERCHANT_ID: (state, id) => {
      state.merchantId = id;
    },
    SET_TABLE_ID: (state, id) => {
      state.tableId = id;
    },
    SET_ORDER_ID: (state, orderId) => {
      state.orderId = orderId;
    },
    SET_CODE: (state, code) => {
      state.code = code;
    },
  },
  actions: {
    getMerchant: ({ commit }, baseData) => {
      //commit("SET_MERCHANT_ID", baseData.merId);
      //commit("SET_TABLE_ID", baseData.tabId);
      return new Promise((resolve, reject) => {
        getMerchant(baseData)
          .then((res) => {
            const {
              data: { merchant, table, order, user, token },
            } = res;
            commit("SET_MERCHANT_ID", merchant.org_id);
            commit("SET_TABLE_ID", table.id);
            commit("SET_CURRENT_RESTAURANT", {
              name: merchant.name,
              pos: table.name,
              pic: merchant.logo,
              headImg: merchant.head_img,
              printNo: order.print_no,
              isPay: merchant.weixin_pay != 1,
              isPayPrint: merchant.weixin_pay === 2,
              isSingle: table.table_mode === 1,
              isClose: merchant.status === 1,
            });
            commit("SET_CURRENT_USER", {
              avatar: user.headImgUrl,
              nickname: user.nickname,
              openId: user.openId,
            });
            Cookies.set("user-token", token);
            if (order) {
              console.log(order);
              order.id && commit("SET_ORDER_ID", order.id);
              order.user_no && commit("SET_CURRENT_EATER_NUM", order.user_no);
            }
            resolve(order);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getMenu: ({ commit, state, dispatch }, num) => {
      return new Promise((resolve, reject) => {
        /* query menu */
        menuList({
          merId: state.merchantId,
          tabId: state.tableId || 1,
          diners: num,
        })
          .then((res) => {
            const { data } = res;
            data.menu = data.menu.filter((value) => value.children.length > 0);
            const top = [];
            data.menu.forEach((value) => {
              value.children.forEach((val) => {
                if (val.is_top === 1) {
                  top.push(val);
                }
                Vue.set(val, "loading", false);
              });
            });
            if (top.length > 0) {
              top.sort((a, b) => a.sort - b.sort);
              const recommend = {
                id: 0,
                sort: 0,
                name: "推荐菜",
                discount: 100,
                type: "top",
                children: top,
              };
              data.menu.unshift(recommend);
            }
            commit("SET_TOTAL_GOODS", data.menu);
            data.orderId ? commit("SET_ORDER_ID", data.orderId) : null;
            commit("SET_CURRENT_EATER_NUM", num);
            dispatch("getNewCart", {
              list: data.menu,
              orderId: state.orderId,
              resolve,
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getNewCart({ commit }, { list, orderId, resolve }) {
      getShoppingCart(orderId)
        .then((res) => {
          if (res.data) {
            const cart = JSON.parse(res.data);
            if (list.length > 0 && cart) {
              try {
                const new_cart = [];
                cart.forEach((val) => {
                  val.loading = false;
                  let result = null;
                  if (!val.pos) {
                    // search new val
                    result = search(val.id, list).target;
                  } else {
                    const [x, y] = val.pos;
                    const t = list[x].children[y];
                    if (!t || val.id !== t.id) {
                      result = search(val.id, list).target;
                    } else {
                      result = t;
                    }
                  }
                  if (result) {
                    Vue.set(result, "total", val.total);
                    Vue.set(result, "userStock", val.userStock);
                    new_cart.push(result);
                  }
                });
                commit("cart/SET_GOODS", new_cart, { root: true });
              } catch (e) {
                console.log(e);
                Toast.fail("购物车数据有误， 请尝试清空购物车");
              }
            }
          }
          resolve ? resolve(orderId) : null;
        })
        .catch(() => {
          Toast.fail("请求购物车失败， 请稍后再试");
        });
    },
  },
  getters: {
    checkDataReady: (state) => {
      return state.merchantId && state.tableId && state.currentUser.openId;
    },
  },
};
