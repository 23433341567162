export default {
  namespaced: true,
  state: () => ({
    naviStatus: "forward",
    isMerchant: false,
    isError: false,
    errorMessage: "非法请求",
    ws: null,
    keepAlive: [],
    NotKeepAlive: [],
  }),
  mutations: {
    SET_ERROR_MESSAGE: (state, msg) => {
      state.errorMessage = msg;
    },
    SET_ERROR: (state, status) => {
      state.isError = status;
    },
    SET_NAVI_STATUS: (state, status) => {
      state.naviStatus = status;
    },
    SET_USER_MERCHANT: (state, status) => {
      state.isMerchant = status;
    },
    SET_WEBSOCKET: (state, ws) => {
      state.ws = ws;
    },
    ADD_KEEP_ALIVE_LIST: (state, name) => {
      if (
        name &&
        state.NotKeepAlive.indexOf(name) < 0 &&
        state.keepAlive.indexOf(name) < 0
      ) {
        state.keepAlive.push(name);
      }
    },
    ADD_NO_KEEP_ALIVE_LIST: (state, name) => {
      if (state.NotKeepAlive.indexOf(name) < 0) {
        state.NotKeepAlive.push(name);
      }
    },
    CLEAR_KEEP_ALIVE: (state) => {
      state.keepAlive = [];
    },
  },
};
