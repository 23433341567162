<template>
  <overlay :show="loading">
    <div class="wrapper">
      <loading type="spinner" size="30" />
    </div>
  </overlay>
</template>

<script>
import { Overlay, Loading } from "vant";
export default {
  name: "loadingLayout",
  components: { Overlay, Loading },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
