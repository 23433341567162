import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Complete from "../views/OrderComplete";
import Error from "../views/Error";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: { alive: false },
    component: () =>
      import(/* webpackChunkName: "info" */ "../views/Login.vue"),
  },
  {
    path: "/info",
    name: "Info",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "info" */ "../views/Info.vue"),
  },
  {
    path: "/shopping",
    name: "Shopping",
    component: () =>
      import(/* webpackChunkName: "shopping" */ "../views/Shopping.vue"),
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import(/* webpackChunkName: "cart" */ "../views/Cart.vue"),
  },
  {
    path: "/complete",
    name: "Complete",
    component: Complete,
  },
  {
    path: "/error",
    name: "Error",
    meta: { alive: false },
    component: Error,
  },
  {
    path: "/merchantHome",
    name: "MerchantHome",
    redirect: "/merchantHome/merchantBizMgr",
    component: () =>
      import(
        /* webpackChunkName: "merchantHome" */ "../views/Merchant/MerchantHome"
      ),
    children: [
      {
        path: "merchantBizMgr",
        name: "MerchantBizMgr",
        component: () =>
          import(
            /* webpackChunkName: "merchantBizMgr" */ "../views/Merchant/MerchantBizMgr"
          ),
      },
      {
        path: "merchantMenu",
        name: "MerchantMenu",
        component: () =>
          import(
            /* webpackChunkName: "merchantMenu" */ "../views/Merchant/MerchantMenu"
          ),
      },
      {
        path: "merchantOrder",
        name: "MerchantOrder",
        component: () =>
          import(
            /* webpackChunkName: "merchantOrder" */ "../views/Merchant/MerchantOrder"
          ),
      },
      {
        path: "merchantMe",
        name: "MerchantMe",
        component: () =>
          import(
            /* webpackChunkName: "MerchantMe" */ "../views/Merchant/MerchantMe"
          ),
      },
      {
        path: "merchantAllOrder",
        name: "MerchantAllOrder",
        component: () =>
          import(
            /* webpackChunkName: "merchantAllOrder" */ "../views/Merchant/MerchantAllOrder"
          ),
      },
      {
        path: "merchantBizData",
        name: "MerchantBizData",
        component: () =>
          import(
            /* webpackChunkName: "MerchantBussData" */ "../views/Merchant/MerchantBizData"
          ),
      },
      {
        path: "merchantModifyPwd",
        name: "MerchantModifyPwd",
        component: () =>
          import(
            /* webpackChunkName: "MerchantModifyPwd" */ "../views/Merchant/MerchantModifyPwd"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
