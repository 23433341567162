<template>
  <div class="home">
    <loading-layout :loading="loading" />
    <div
      v-show="
        orderId &&
        !(orderId && orderUnDone.length <= 0 && orderDone.length <= 0)
      "
      class="flex-column no-expand"
      style="background: #fff; height: 100%"
    >
      <div class="card-container">
        <div v-if="orderUnDone.length > 0 || orderDone.length > 0">
          <div
            class="flex-row cart-title common-card three-d-shadow-mid"
            style="background: #fff"
          >
            <div class="back-circle">
              <van-icon name="logistics" />
            </div>
            <span>您的美食正火速赶来</span>
            <span v-if="!merchant.isSingle"> ，祝您用餐愉快！ </span>
            <van-button
              v-else
              round
              type="primary"
              size="small"
              style="margin-left: auto"
              @click="show = true"
              >查看取餐码</van-button
            >
          </div>
        </div>
        <div v-else>
          <div
            class="flex-row cart-title common-card three-d-shadow-mid"
            style="background: #fff; font-size: 0.8rem"
          >
            <div>
              <van-image
                width="2rem"
                height="2rem"
                round
                fit="cover"
                :src="user.avatar"
              />
            </div>
            <span style="margin-left: 5px">{{ user.nickname }}</span>
            <span> , 欢迎光临！ </span>
          </div>
        </div>
      </div>
      <div
        style="flex: 1; padding: 0 0.6rem; box-sizing: border-box"
        class="no-expand"
        v-if="code"
      >
        <div
          v-if="orderUnDone.length > 0 || orderDone.length > 0"
          class="common-card full"
          style="box-sizing: border-box; background-color: transparent"
        >
          <div class="order-detail flex-column no-expand" style="height: 100%">
            <div
              class="order-title flex-row"
              style="
                font-size: 1rem;
                padding: 0.2rem 0;
                align-items: center;
                border-bottom: 1px solid #dddddd;
              "
            >
              <van-icon name="bill-o" />
              <span style="font-size: 0.7rem; margin-left: 5px">{{
                order.name
              }}</span>
              <my-switch
                style="margin-left: auto"
                v-model="currentOrder"
                :label="label"
                @change="refreshScroll"
              />
            </div>
            <scroll ref="scroll">
              <div style="padding-top: 0.3rem">
                <table class="my-table" style="font-size: 0.65rem; width: 100%">
                  <thead>
                    <tr>
                      <td style="width: 60%">名称</td>
                      <td style="width: 20%">数量</td>
                      <td style="text-align: right">单价</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="g in order.list" :key="g.goods_id">
                      <td>
                        <div>
                          <van-image
                            round
                            style="flex-shrink: 0"
                            width="1.4rem"
                            height="1.4rem"
                            fit="cover"
                            :src="g.img_url"
                          />
                          <span style="margin-left: 5px">{{
                            g.goods_name
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div>x{{ g.total_count }}</div>
                      </td>
                      <td>
                        <div>¥{{ g.total_amount / 100 }}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </scroll>
          </div>
        </div>
      </div>
      <div
        style="
          margin-top: auto;
          border-radius: 30px 30px 0 0;
          box-shadow: 0 0 9px 3px #dddddd;
        "
        class="card-container no-shrink"
        v-if="code"
      >
        <div
          style="text-align: center; font-size: 0.8rem; padding-bottom: 1rem"
        >
          共<span class="common-color">{{ total.count }}</span
          >份菜，总计<span class="common-color">{{ total.total }}</span
          >元
        </div>
        <div class="flex-row">
          <van-button
            icon="cart-circle-o"
            plain
            round
            type="primary"
            style="flex: 1"
            :loading="loading"
            :disabled="allowButton"
            to="shopping"
            >{{
              orderUnDone.length > 0 || orderDone.length > 0
                ? "继续加菜"
                : "点餐"
            }}</van-button
          >
          <div style="width: 0.6rem" v-if="orderUnDone.length > 0" />
          <van-button
            v-if="orderUnDone.length > 0"
            icon="cart-circle-o"
            round
            type="primary"
            style="flex: 1"
            :disabled="loading"
            @click="pay"
            >付款</van-button
          >
        </div>
      </div>
      <van-dialog class="home-dialog" v-if="merchant.printNo" v-model="show">
        <template #title>
          <div style="position: relative; height: 1rem">
            &nbsp;
            <div
              style="
                position: absolute;
                left: 50%;
                bottom: -0.5rem;
                transform: translateX(-50%);
              "
            >
              <van-image
                round
                width="3rem"
                height="3rem"
                fit="cover"
                style="box-shadow: 0 0 1px 3px #dddddd"
                :src="user.avatar"
              />
            </div>
          </div>
        </template>
        <div class="flex-column" style="align-items: center; padding: 0.8rem">
          <div style="font-size: 0.8rem; padding: 0.4rem">
            <span>{{ user.nickname }}</span>
          </div>
          <div>
            <span style="font-size: 0.8rem; color: #5b6363">您的取餐码为:</span>
            <span style="font-size: 1.6rem; font-weight: bold; color: #f76045">
              {{ merchant.printNo && merchant.printNo.slice(-3) }}
            </span>
          </div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import "@/utils/rem";
import Cookies from "js-cookie";
import { mapState } from "vuex";
import {
  Button as VanButton,
  Icon as VanIcon,
  Image as VanImage,
  Dialog,
} from "vant";
import LoadingLayout from "@/components/loadingLayout";
import wx from "weixin-js-sdk";
import { createOrder, findOrderById } from "@/api/base";
import Scroll from "@/components/scroll";
import MySwitch from "@/components/switch";
export default {
  name: "Home",
  components: {
    MySwitch,
    Scroll,
    LoadingLayout,
    VanButton,
    VanIcon,
    VanImage,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      loading: false,
      label: {
        left: { name: "未支付", value: "orderUnDone" },
        right: { name: "已支付", value: "orderDone" },
      },
      currentOrder: "orderUnDone",
      show: false,
    };
  },
  watch: {
    $route(to) {
      if (to.name === "Home") {
        this.refresh();
      }
    },
    menu(val) {
      if (val.length > 0) {
        if (this.orderUnDone.length <= 0 && this.orderDone.length <= 0) {
          this.$router.push("/shopping");
        }
      }
    },
  },
  computed: {
    allowButton() {
      if (!this.orderId) {
        return true;
      } else {
        return this.num <= 0;
      }
    },
    order() {
      return {
        name: `${
          this.currentOrder === "orderUnDone" ? "未支付" : "已支付"
        }订单`,
        list: this[this.currentOrder],
      };
    },
    total() {
      let total = 0;
      let count = 0;
      this[this.currentOrder].forEach((value) => {
        total += value.total_amount * value.total_count;
        count += value.total_count;
      });
      total = total / 100;
      return { total, count };
    },
    ...mapState({
      ws: (state) => state.app.ws,
      menu: (state) => state.base.totalGoods,
      code: (state) => state.base.code,
      user: (state) => state.base.currentUser,
      orderId: (state) => state.base.orderId,
      merchantId: (state) => state.base.merchantId,
      merchant: (state) => state.base.currentRestaurant,
      orderDone: (state) => state.base.orderDone,
      orderUnDone: (state) => state.base.orderUnDone,
    }),
  },
  mounted() {
    this.generateOrder();
  },
  methods: {
    refreshScroll() {
      this.$nextTick(() => {
        this.$refs.scroll.refresh();
      });
    },
    generateOrder() {
      const last_use = localStorage.getItem("last_use");
      let token = null;
      //let { merId, tabId, isMer, redirect } = this.$route.query;
      let { sid, isMer, redirect } = this.$route.query; //-
      if (last_use === "merchant" || isMer) {
        token = Cookies.get("token");
      } else if (last_use === "user" && !isMer) {
        /*merId = merId
          ? merId
          : localStorage.getItem("mer_id") === "null"
          ? null
          : localStorage.getItem("mer_id");*/
        /*tabId = tabId
          ? tabId
          : localStorage.getItem("tab_id") === "null"
          ? null
          : localStorage.getItem("tab_id");*/
        sid = sid
          ? sid
          : localStorage.getItem("sid") === "null"
          ? null
          : localStorage.getItem("sid");
      }
      if (sid && !isMer) {
        localStorage.removeItem("sid");
        //localStorage.removeItem("mer_id");
        //localStorage.removeItem("tab_id");
        localStorage.setItem("last_use", "user");
        const code = this.getUrlParam("code");
        const last_code = localStorage.getItem("code");
        const local =
          code && code === last_code
            ? location.origin +
              `/#/?sid=${sid}` +
              (redirect ? "&redirect=" + redirect : "")
            : window.location.href;
        if (code == null || code === "" || (code && code === last_code)) {
          window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            process.env.VUE_APP_APPID +
            "&redirect_uri=" +
            encodeURIComponent(local) +
            "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
          return;
        }
        localStorage.setItem("code", code);
        this.$store.commit("base/SET_SID", sid);
        this.$store.commit("base/SET_CODE", code);
        this.loading = true;
        this.$store.dispatch("base/getMerchant", { sid, code }).then((res) => {
          this.loading = false;
          if (res && res.id) {
            this.$store.commit("base/SET_ORDER_DONE", res.orderDetails);
            this.$store.commit("base/SET_ORDER_UNDONE", res.unpaidOrderDetails);
            this.$nextTick(() => {
              this.$refs.scroll ? this.$refs.scroll.refresh() : null;
            });
          } else {
            // this.$router.push("Info");
          }
        });
      } else if (isMer || token) {
        localStorage.setItem("last_use", "merchant");
        if (token) {
          this.$store.commit("base/SET_MERCHANT_ID", Cookies.get("mer_id"));
          this.$store.commit(
            "base/SET_CURRENT_RESTAURANT",
            JSON.parse(Cookies.get("mch"))
          );
        }
        this.$store.commit("app/SET_USER_MERCHANT", true);
        this.$router.push("/login");
      } else {
        this.$store.commit("app/SET_ERROR", true);
        this.$router.push("/error");
      }
      /**/
      // 测试商家端
    },
    refresh() {
      const orderId = this.orderId;
      if (orderId) {
        findOrderById(orderId)
          .then((res) => {
            if (res.data) {
              const data = res.data;
              this.$store.commit("base/SET_ORDER_DONE", data.orderDetails);
              this.$store.commit(
                "base/SET_ORDER_UNDONE",
                data.unpaidOrderDetails
              );
              if (this.orderDone.length <= 0 && this.orderUnDone.length <= 0) {
                this.$router.push("/shopping");
              } else {
                this.$nextTick(() => {
                  this.$refs.scroll ? this.$refs.scroll.refresh() : null;
                });
              }
            }
          })
          .catch(() => {
            this.$toast.fail("查询已有订单失败，请重新扫码");
          });
      } else {
        this.$router.push("/info");
      }
    },
    getUrlParam(name) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let url = window.location.href.split("#")[0];
      let search = url.split("?")[1];
      if (search) {
        const r = search.substr(0).match(reg);
        if (r !== null) return unescape(r[2]);
        return null;
      } else {
        return null;
      }
    },
    pay() {
      if (!this.merchant.isPay) {
        this.$toast.fail({
          message: "请到收银台支付，谢谢",
          closeOnClick: true,
          closeOnClickOverlay: true,
          duration: 5000,
        });
        return;
      }
      const that = this;
      let fee = 0;
      this.orderUnDone.forEach((value) => {
        fee += Number(value.total_amount) * Number(value.total_count);
      });
      this.loading = true;
      createOrder({
        fee: fee,
        openId: this.user.openId,
        orderId: this.orderId,
        merId: this.merchantId,
      })
        .then((res) => {
          var args = res.data;
          if (args.orderAmountNotMatch == true) {
            // 当订单金额有变化则更新
            that.$dialog
              .confirm({ title: "提醒", message: "订单有加菜，请您确认" })
              .then(() => {
                that.refresh();
              })
              .catch(() => {
                that.refresh();
              });
          }

          wx.config({
            debug: false,
            appId: res.data.appId,
            timestamp: res.data.timestamp,
            nonceStr: res.data.noncestr,
            signature: res.data.signature,
            jsApiList: ["chooseWXPay"],
          });
          wx.ready(() => {
            wx.checkJsApi({
              jsApiList: ["chooseWXPay"],
              success: (res) => {
                that.loading = false;
                console.log("checked api:", res);
              },
              fail: (err) => {
                that.loading = false;
                console.log("check api fail:", err);
              },
            });
          });

          wx.chooseWXPay({
            // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            timestamp: args.timeStamp,
            // 支付签名随机串，不长于 32 位
            nonceStr: args.nonceStr,
            // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            package: args.package,
            // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            signType: args.signType,
            // 支付签名
            paySign: args.sign,
            // 支付成功后的回调函数
            success: function (res) {
              that.loading = false;
              // res.errMsg === 'chooseWXPay:ok'方式判断前端返回,微信团队郑重提示：
              // res.errMsg将在用户支付成功后返回ok，但并不保证它绝对可靠， 切记。
              if (res.errMsg === "chooseWXPay:ok") {
                that.$store.push({ path: "home", meta: { pay: true } });
              }
            },
            // 支付取消回调函数
            cancel: function () {
              that.loading = false;
              alert("交易已取消");
            },
            // 支付失败回调函数
            fail: function () {
              that.loading = false;
              alert("支付失败请联系管理员");
            },
          });
        })
        .catch(() => {
          that.loading = false;
          that.$toast.fail("调起支付失败，请联系商户");
        });
    },
  },
};
</script>
<style lang="less" scoped>
.home-dialog {
  overflow: visible;
  /deep/.van-dialog__header {
    padding: 0;
  }
  /deep/.van-dialog__footer {
    border-radius: 0 0 30px 30px;
  }
}
.home {
  .card-container {
    padding: 0.6rem;
    .cart-title {
      font-size: 0.6rem;
      align-items: center;
      padding: 0.6rem 0.4rem;
    }
  }
  width: 100%;
  height: 100%;
  .my-table {
    border-collapse: separate;
    border-spacing: 0 10px;
    thead td {
      padding-bottom: 0.1rem;
    }
    tbody td {
      padding: 0.05rem 0;
      border-bottom: 1px solid #dddddd;
      vertical-align: middle;
      &:last-child {
        & > div {
          text-align: right;
        }
        color: @primary-color;
      }
      & > div {
        padding: 0.4rem 0;
      }
      &:first-child > div {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
