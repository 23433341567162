import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Fastclick from "fastclick";
import "animate.css";

import { Toast, Dialog } from "vant";
Vue.use(Toast);
Vue.use(Dialog);
// 引入全部样式
/*import "vant/lib/index.less";*/
import "@/assets/style/common.less";

import "./permission";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
Fastclick.attach(document.body);
