import axios from "axios";
import { Notify } from "vant";
import store from "@/store/index";
import router from "@/router/index";
// import store from "@/store";
import errorCode from "@/utils/errorCode";
import Cookies from "js-cookie";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // 超时
  timeout: 150000,
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    var token;
    if (store.state.app.isMerchant) {
      // 商家校验
      token = Cookies.get("token");
    } else if (
      store.state.base.merchantId &&
      store.state.base.currentUser.openId
    ) {
      token = Cookies.get("user-token");
    }
    config.headers["session_token"] = token;
    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?";
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof value !== "undefined") {
          if (typeof value === "object") {
            for (const key of Object.keys(value)) {
              let params = propName + "[" + key + "]";
              var subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    if (code === 500) {
      Notify({
        message: msg,
        type: "danger",
      });
      return Promise.reject(new Error(msg));
    } else if (code !== 200) {
      Notify({
        message: msg,
        type: "danger",
      });
      return Promise.reject("error");
    } else if (res.data.result_code === "FAIL") {
      if (res.data.err_code === "LOGIN_TIMEOUT") {
        Notify({
          message:
            "无效的登陆请求" +
            (store.state.app.isMerchant ? "，请重新登录" : "，请重新扫码"),
          type: "danger",
        });
        if (store.state.app.isMerchant) {
          Cookies.remove("token");
          router.push("/login");
        } else {
          Cookies.remove("user-token");
          store.commit("app/SET_ERROR", true);
          router.push("/error");
        }
      } else {
        Notify({
          message: res.data.err_des,
          type: "danger",
        });
      }
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  (error) => {
    console.log("err" + error);
    let { message } = error;
    if (message === "Network Error") {
      message = "网络连接错误";
    } else if (message.includes("timeout")) {
      message = "网络请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统" + message.substr(message.length - 3) + "异常";
    }
    Notify({
      message: message,
      type: "danger",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
