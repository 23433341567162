<template>
  <div
    style="
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      background-color: white;
    "
    class="flex-row"
  >
    <van-empty image="error" :description="$store.state.app.errorMessage" />
  </div>
</template>

<script>
import { Empty as VanEmpty } from "vant";
export default {
  name: "Error",
  components: {
    VanEmpty,
  },
};
</script>

<style scoped></style>
