<template>
  <div class="barrage-container">
    <div style="width: 100%; position: relative">
      <transition-group>
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          style="position: absolute; top: 0"
          :style="{ top: item.pos + 'px' }"
        >
          <div style="display: flex; align-items: center">
            <van-image
              round
              width="1rem"
              height="1rem"
              fit="cover"
              :src="item.data.user.avatar"
            />
            <span>点了</span>
            <span>{{ item.data.good.name }}</span>
            <span>x{{ item.data.value }}</span>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { Image as VanImage } from "vant";
export default {
  name: "barrage",
  data() {
    return {
      time: 6000,
      list: [],
    };
  },
  components: {
    VanImage,
  },
  methods: {
    addElement(obj) {
      //obj默认以增加购物车时传递的数据为格式
      const target = {
        id: new Date().getTime(),
        data: obj,
        pos: Math.floor(Math.random() * 271) + 30,
      };
      this.list.push(target);
      setTimeout(() => {
        this.list.splice(this.list.indexOf(target), 1);
      }, this.time);
    },
  },
};
</script>

<style lang="less" scoped>
.barrage-container {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  .item {
    color: whitesmoke;
    font-size: 0.7rem;
    right: -50%;
    background-color: @primary-color;
    padding: 0.2rem 0.4rem;
    border-radius: 44px;
    animation: danmu 6s linear 0s;
  }
}
@keyframes danmu {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
    transform: translateX(-120vw);
  }
}
</style>
